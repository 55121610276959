import logo from './logo.svg';
import './App.css';

export default function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>Under Construction!</h1>
        <p>
          Website coming soon.
        </p>
      </header>
    </div>
  );
}
